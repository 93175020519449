import React, { Component } from 'react'
import moment from 'moment';
import deviceIconActive from './icons/device-active.svg';
import deviceIconInactive from './icons/device-inactive.svg';


export default class DeviceInfo extends Component {
  render() {
    const { lastDevicePing, pingThreshold, loading } = this.props;
    const deviceActive = Date.now() - lastDevicePing.time < pingThreshold;

    return (
      <div className="device-info status-display">
        <div className="icon-container">
          <img src={deviceActive ? deviceIconActive : deviceIconInactive} className={loading ? "loading" : ""} height="20px" alt="smartphone" />
        </div>
        <span>
          {moment(lastDevicePing.time).fromNow()} | {lastDevicePing.battery}%
        </span>
      </div>
    )
  }
}
