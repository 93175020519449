import React, { Component } from 'react'
import moment from 'moment';
import databaseIconActive from './icons/database-active.svg';
import databaseIconInactive from './icons/database-inactive.svg';

export default class DatabaseInfo extends Component {
  render() {
    const { lastDatabasePing, pingThreshold } = this.props;
    const databaseActive = Date.now() - lastDatabasePing.time < pingThreshold;

    return (
      <div className="status-display">
        <div className="icon-container">
          <img src={databaseActive ? databaseIconActive : databaseIconInactive} height="20px" alt="database" />
        </div>
        <span>
          {moment(lastDatabasePing.time).fromNow()}
        </span>
      </div>
    )
  }
}
