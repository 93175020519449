// helper.js
import axios from 'axios';

// CORS enabled apikey
const apikey = process.env.REACT_APP_CORS_KEY;

// restdb.io database name
const database = process.env.REACT_APP_DATABASE;

// REST endpoint
let restdb = axios.create({
  baseURL: `https://${database}.restdb.io`,
  timeout: 10000,
  headers: { 'x-apikey': apikey }
});
// Eventsource endpoint
const realtimeURL = `https://${database}.restdb.io/realtime?apikey=${apikey}`

export { apikey, restdb, realtimeURL };