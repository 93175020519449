import React from 'react';
import { restdb, realtimeURL } from './helper'
import './App.css'
import AlarmBell from './AlarmBell';
import DeviceInfo from './DeviceInfo';
import DatabaseInfo from './DatabaseInfo';
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const ALARM_DURATION = 1000 * 5; // in ms
const TIMEOUT_CHECK_INTERVAL = 10000; // in ms
const TIMEOUT_CHECK_THRESHOLD = 20; // in seconds
const DEVICE_PING_THRESHOLD = 10 * 60 * 1000; // in ms
const TIME_BETWEEN_DISCONNECTED_NOTIFICATIONS = 5 * 60 * 1000; // in ms

class App extends React.Component {

  state = {
    timeoutCheckActive: false,
    loadingPastPings: false,
    lastDisconnectedNotification: 0,
    sendNotifications: Notification.permission === "granted",
    lastRing: {
      time: 0
    },
    lastDevicePing: {
      time: 0,
      battery: 50
    },
    lastDatabasePing: {
      time: Date.now()
    }
  };

  render() {
    const { lastRing, lastDevicePing, lastDatabasePing, loadingPastPings, sendNotifications } = this.state;
    const now = Date.now();
    const alarmActive = now - lastRing.time < ALARM_DURATION;

    return (
      <div className="App">
        <header className="App-header">
          <AlarmBell className="App-logo" alarmActive={alarmActive} />
          <DeviceInfo lastDevicePing={lastDevicePing} pingThreshold={DEVICE_PING_THRESHOLD} loading={loadingPastPings} />
          <DatabaseInfo lastDatabasePing={lastDatabasePing} pingThreshold={TIMEOUT_CHECK_THRESHOLD * 1000} />
          <FormControlLabel
            className="notifications-switch"
            control={
              <Switch
                name="notificationsSwitch"
                checked={sendNotifications}
                onChange={this.onNotificationsToggled.bind(this)} />
            }
            label="Send Notifications"
            labelPlacement="start"
          />
        </header>
      </div>
    )
  }

  componentDidMount() {
    // connect to the realtime database stream
    const eventSource = new EventSource(realtimeURL);

    // listen on ping from server, keep time
    eventSource.addEventListener('ping', e => {
      this.onDatabasePing(new Date(e.data).getTime());
    }, false);

    // listen for database REST operations
    eventSource.addEventListener('post', (e) => {
      const data = JSON.parse(e.data);
      const entry = data.data;

      if (data.collection === "rings") {
        this.onDoorbellRing(entry);
      } else if (data.collection === "pings") {
        this.onDevicePing(entry);
      }
    }, false);

    this.setState({
      loadingPastPings: true
    });
    restdb.get(`/rest/pings?&max=1&h={"$orderby": {"time": -1}}`)
      .then(res => {
        this.setState({
          loadingPastPings: false,
          lastDevicePing: res.data[0]
        })
      })
      .catch(console.error);
  }

  onDatabasePing(time) {
    if (this.state.timeoutCheckActive) {
      this.setState({
        lastDatabasePing: {
          time: time
        }
      });
    } else {
      this.setState({
        lastDatabasePing: {
          time: time
        },
        timeoutCheckActive: true
      });

      setInterval(() => {
        let now = Date.now();
        let diff = (now - this.state.lastDatabasePing.time) / 1000;

        // haven't heard from the server in 20 secs?
        if (diff > TIMEOUT_CHECK_THRESHOLD) {
          // hard reload of client
          window.location.reload();
        }

        if (now - this.state.lastDevicePing.time > DEVICE_PING_THRESHOLD
          && now - this.state.lastDisconnectedNotification > TIME_BETWEEN_DISCONNECTED_NOTIFICATIONS) {
          if (this.state.sendNotifications) {
            new Notification("Detection Device disconnected", {
              icon: '/doorbell-detect-error.png'
            });
            this.setState({
              lastDisconnectedNotification: now
            })
          }
        }
      }, TIMEOUT_CHECK_INTERVAL);
    }
  }

  onDevicePing(ping) {
    this.setState({
      lastDevicePing: ping
    });
  }

  onDoorbellRing(ring) {
    console.log('New Ring detected', ring);

    if (this.state.sendNotifications) {
      new Notification("Doorbell ringing!", {
        icon: '/doorbell-detect-alarm.png'
      });
    }
    this.playAudioAlarm(3);

    this.setState({
      lastRing: ring
    });
  }

  playAudioAlarm(count) {
    let audio = new Audio('doorbell-sound.mp3');
    audio.play();
    if (count > 0) {
      setTimeout(() => {
        this.playAudioAlarm(count - 1);
      }, 2000);
    }
  }

  onNotificationsToggled(e) {
    const checked = e.target.checked;
    if (checked) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission()
          .then(value => {
            if (value !== "granted") {
              this.setState({
                sendNotifications: false
              });
            } else {
              this.setState({
                sendNotifications: checked
              });
            }
          });
        return;
      }
    }
    this.setState({
      sendNotifications: checked
    });
  }
}

export default App;
