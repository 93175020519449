import React, { Component } from 'react';
import bell from './icons/bell.svg';
import ringingBell from './icons/bell-ringing.svg';

export default class AlarmBell extends Component {
  render() {
    const { alarmActive } = this.props;
    return (
      <img src={alarmActive ? ringingBell : bell} className={`App-logo ${alarmActive ? 'animate-bell' : ''}`} alt="logo" />
    )
  }
}
